<template>
  <div class="action-bar d-flex flex-wrap align-center">
    <div class="d-flex align-center date-text pr-3 mr-3">
      <v-icon
        size="25"
        v-ripple
        @click="change_selected_date({ backwards: true })"
        >mdi-chevron-left</v-icon
      >
      <span>{{
        selected_booking_date
          ? moment(selected_booking_date).format("Do MMM")
          : ""
      }}</span>
      <v-icon
        size="25"
        v-ripple
        @click="change_selected_date({ forwards: true })"
        >mdi-chevron-right</v-icon
      >
    </div>
    <v-btn
      rounded
      class="mr-6"
      color="yellow"
      elevation="1"
      @click="add_new_booking"
    >
      <span class="button-text">New Booking</span>
    </v-btn>
    <NewGroupBookingDialog v-if="settings.use_group_bookings" />

    <div class="d-flex align-center mr-2">
      <v-btn
        fab
        width="20"
        height="20"
        class="mr-0"
        elevation="1"
        :class="{ disabled: timelineScale == 4 }"
        @click="change_scale(-4)"
        ><v-icon small> mdi-minus </v-icon></v-btn
      >
      <span class="zoom-text text-center mr-1 ml-2" width="300"
        >{{ timelineScale * 25 }}%</span
      >
      <v-btn
        fab
        width="20"
        height="20"
        elevation="1"
        :class="{ disabled: timelineScale == 12 }"
        @click="change_scale(+4)"
        ><v-icon small> mdi-plus </v-icon></v-btn
      >
    </div>
    <div class="status-dropdown">
      <p class="d-flex align-center">
        {{ currently_selected_status }}
        <v-icon class="ml-1">mdi-chevron-down</v-icon>
      </p>

      <ul>
        <li
          v-for="{ text, value } of BOOKING_STATUS_ITEMS.filter(
            ({ value }) => value !== filters.booking_status
          )"
          :key="`status-${value}`"
          @click="
            apply_filters({ ...filters, booking_status: value }), get_bookings()
          "
        >
          {{ text }}
        </li>
      </ul>
    </div>
    <div class="ml-auto">
      <v-tooltip
        bottom
        content-class="custom-tooltip"
        :disabled="!is_selected_booking_date_today || allow_new_walk_in"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              rounded
              class="mr-0"
              :class="{ hidden: !is_selected_booking_date_today }"
              color="primary"
              elevation="1"
              :disabled="!allow_new_walk_in"
              @click="add_walk_in"
            >
              <span class="button-text">Walk-In</span>
            </v-btn>
          </div>
        </template>
        <span
          >Cannot accomodate a walk-in as the venue is fully booked for the
          day</span
        >
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapGetters } from "vuex";
import { BOOKING_STATUS_ITEMS } from "@/shared/const";
import NewGroupBookingDialog from "./NewGroupBookingDialog.vue";

export default {
  name: "BookingActionBar",
  components: { NewGroupBookingDialog },
  data() {
    return {
      moment,
      BOOKING_STATUS_ITEMS: [
        { text: "All Statuses", value: null },
        ...BOOKING_STATUS_ITEMS.filter((_) => _.value !== "cancelled"),
      ],
    };
  },
  props: {
    timelineScale: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    ...mapState("TableBookingStore", [
      "selected_booking_date",
      "allowed_dates",
      "filters",
      "settings",
    ]),
    ...mapGetters("TableBookingStore", ["is_available_for_walk_in"]),
    today_date() {
      return moment().format("YYYY-MM-DD");
    },
    is_selected_booking_date_today() {
      return this.selected_booking_date === this.today_date;
    },
    allow_new_walk_in() {
      return (
        this.is_selected_booking_date_today && this.is_available_for_walk_in
      );
    },
    currently_selected_status() {
      const current_status = this?.filters?.booking_status ?? null;
      if (!current_status) return "All Statuses";
      return BOOKING_STATUS_ITEMS.find(
        (status) => status.value === current_status
      ).text;
    },
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "toggle_show_new_booking",
      "set_selected_date",
      "apply_filters",
      "get_bookings",
    ]),
    change_scale(value) {
      value > 0
        ? this.$emit("change-scale", Math.min(this.timelineScale + value, 12))
        : this.$emit("change-scale", Math.max(this.timelineScale + value, 4));
    },
    add_new_booking() {
      this.$emit("adding-walk-in", false);
      this.toggle_show_new_booking();
    },
    add_walk_in() {
      this.$emit("adding-walk-in", true);
      this.toggle_show_new_booking();
    },
    change_selected_date({ forwards = false, backwards = false }) {
      const current_date = this.selected_booking_date;
      const format = "YYYY-MM-DD";
      let new_date;
      if (forwards)
        new_date = moment(current_date, format).add(1, "d").format(format);
      else if (backwards)
        new_date = moment(current_date, "YYYY-MM-DD")
          .subtract(1, "d")
          .format(format);
      else new_date = current_date;

      this.set_selected_date(new_date);
    },
  },
};
</script>

<style lang="scss" scoped>
.ml-auto {
  margin-left: auto;
}
.action-bar {
  grid-column: 1 / span 2;
  height: 110px;
  margin-bottom: 6px;
  @media only screen and (min-width: 1121px) {
    height: 65px;
  }
  .date-text {
    display: inline-flex;
    justify-content: space-between;
    width: 240px;
    font-size: 20px;
    font-weight: bold;
    color: #666666;

    .v-icon {
      cursor: pointer;
      background: transparent;
      border-radius: 50%;
      &:hover {
        color: var(--v-primary-base);
        // background: rgba(0, 0, 0, 0.05);
      }
      &:focus {
        &:after {
          opacity: 0;
        }
      }
    }
  }
  .button-text {
    // font-size: 15px;
    font-weight: 500;
  }

  .status-dropdown {
    cursor: pointer;
    position: relative;
    p {
      font-size: 15px;
      font-weight: bold;
      color: #666666;
      margin-bottom: 0;
    }
    ul {
      transition: all 0.2s ease-in-out;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.178);
      border-radius: 8px;
      z-index: -10;
      opacity: 0;
      position: absolute;
      padding: 4px;
      top: 20px;
      list-style-type: none;
      li {
        cursor: pointer;
        margin-bottom: 4px;
        padding: 4px;
        border-radius: 4px;
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      ul {
        opacity: 1;
        z-index: 10;
      }
    }
  }
  .zoom-text {
    font-size: 13px;
    display: inline-block;
    width: 35px;
    font-weight: bold;
    color: #666666;
  }
  .disabled {
    color: #999999;
    background-color: #f7f7f7 !important;
    &:hover {
      cursor: default;
    }
  }
}
</style>
