<template>
  <div
    class="timeline"
    :class="{
      '--drop-target': is_drop_target,
    }"
    :style="`grid-template-columns: repeat(${
      6 * intervals_per_hour * scale
    }, ${timeline_node_width}px);`"
    @drop="on_drop($event)"
    @dragover.prevent
    @dragenter.prevent="on_drag_enter"
    @dragleave.prevent="on_drag_leave"
  >
    <div
      class="booking-node"
      :class="{ disabled: booking.status === BOOKING_STATUS.NO_SHOW }"
      :style="`
        grid-column: ${get_booking_node_columns(booking)};
        background-color: ${status_map[booking.status].color}
      `"
      v-for="booking in bookings"
      :key="`booking_node_${booking.id}`"
      @click="select_booking(booking)"
      draggable
      @dragstart="on_start_drag($event, booking)"
    >
      <div class="d-flex justify-space-between align-center">
        <span class="name text-truncate flex-shrink-1">{{
          get_booking_node_text(booking)
        }}</span>
        <template v-if="!booking.parent_booking_id">
          <span class="display-inline-block mx-1">
            ({{
              booking.extra_guest_count
                ? `${booking.guest_count} + ${booking.extra_guest_count}`
                : booking.guest_count
            }})
          </span>
        </template>
        <v-spacer />
        <div class="d-flex fill-height mr-2">
          <template v-if="!booking.parent_booking_id">
            <v-icon
              small
              v-if="booking.notes !== null"
              v-text="`$note-circle`"
              color="white"
              class="mr-1"
            ></v-icon>
          </template>
          <v-icon
            v-if="
              ![
                BOOKING_STATUS.NEW,
                BOOKING_STATUS.CANCELLED,
                BOOKING_STATUS.CONFIRMED,
              ].includes(booking.status)
            "
            small
            v-text="status_map[booking.status].icon"
            :color="status_map[booking.status].icon_color"
          ></v-icon>
          <v-icon
            small
            v-if="
              ![
                BOOKING_STATUS.NO_SHOW,
                BOOKING_STATUS.NEW,
                BOOKING_STATUS.CANCELLED,
              ].includes(booking.status)
            "
            v-text="
              `mdi-check${
                booking.status === BOOKING_STATUS.COMPLETED ? '-all' : ''
              }`
            "
            color="primary"
          ></v-icon>
        </div>
      </div>
    </div>
    <div
      class="break-section"
      v-for="(section, i) in break_section_data"
      :key="`break_${i}`"
      :style="`left: ${section.start}px; width: ${
        section.end - section.start
      }px; background-image: url(${require('@/assets/stripes.png')}); background-position: left -${bgOffset}px`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import { BOOKING_STATUS_CHIP_DATA } from "@/shared/const";
import { BOOKING_STATUS } from "@/shared/const";
import { patch_booking } from "@/requests";

export default {
  name: "TableTimeline",
  props: {
    table: {
      required: true,
    },
    scale: {
      type: Number,
      default: 4,
    },
    businessHourPositions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bgOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      status_map: BOOKING_STATUS_CHIP_DATA,
      BOOKING_STATUS,
      is_drop_target: false,
    };
  },
  computed: {
    ...mapState("StaffStore", ["staff"]),
    ...mapState("UserStore", ["api_user_id"]),
    ...mapGetters("TableBookingStore", [
      "bookings_chronologically",
      "intervals_per_hour",
      "timeline_node_width",
    ]),
    bookings() {
      return this.bookings_chronologically.filter((b) => {
        return b.table_id == this.table.id;
      });
    },
    break_section_data() {
      if (this.businessHourPositions.length == 1) return;
      let section_data = [];
      for (const [i, position] of this.businessHourPositions.entries()) {
        if (i % 2) {
          continue;
        }
        const break_section = {
          start: position,
          end:
            i + 1 > this.businessHourPositions.length - 1
              ? 6 *
                this.intervals_per_hour *
                this.timeline_node_width *
                this.scale
              : this.businessHourPositions[i + 1],
        };
        if (break_section.start != break_section.end) {
          section_data.push(break_section);
        }
      }
      return section_data;
    },
  },
  methods: {
    ...mapActions("TableBookingStore", ["select_booking"]),
    get_booking_node_columns(booking) {
      const start_at_moment = moment(booking.start_at).utc();
      const end_at_moment = moment(booking.end_at).utc();
      const start_column =
        (start_at_moment.format("HH") * this.scale * this.intervals_per_hour) /
          4 +
        Math.floor(
          ((start_at_moment.format("mm") / (60 / this.scale)) *
            this.intervals_per_hour) /
            4
        );
      const time_delta = end_at_moment.diff(start_at_moment, "minutes");
      const span = Math.floor(
        ((time_delta / (60 / this.scale)) * this.intervals_per_hour) / 4
      );
      return `${start_column + 1} / span ${span};`;
    },
    get_booking_node_text(booking) {
      let name;
      const is_walk_in =
        booking.staff_id || booking.user_id === this.api_user_id;
      if (is_walk_in) {
        name = "Walk-in";
      }
      if (!name) name = booking.user.name.split(" ")[0];
      if (booking.parent_booking_id) return `${name} (G)`;
      return name;
    },
    on_start_drag(evt, booking) {
      evt.dataTransfer.dropEffect = "copy";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("bookingId", booking.id);
    },
    async on_drop(evt) {
      this.is_drop_target = false;
      const booking_id = evt.dataTransfer.getData("bookingId");
      if (!booking_id) return;
      await patch_booking(booking_id, {
        table_id: this.table.id,
      });
    },
    on_drag_enter() {
      this.is_drop_target = true;
    },
    on_drag_leave() {
      this.is_drop_target = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  height: 28px;
  display: grid;
  position: relative;
  grid-template-rows: 28px;
  background: white;
  border-radius: 8px;
  overflow-x: unset;
  overflow-y: visible;
  &.--drop-target {
    outline: 2px solid lightgray;
  }
  .booking-node {
    white-space: nowrap;
    padding-left: 12px;
    font-size: 12px;
    color: #666666;
    line-height: 2.4;
    border: 1px solid #707070;
    border-radius: 8px;
    z-index: 1;
    grid-row: 1;

    &.disabled {
      border-color: #bcbcbc;
      color: #bcbcbc;
    }

    cursor: pointer;
    &:hover {
      background: rgba(84, 196, 201, 0.24);
    }

    .name {
      min-width: 0;
    }
    .note-bg {
      border-radius: 50px;
      width: 15px;
      height: 15px;
    }
  }
  .break-section {
    position: absolute;
    height: 31px;
    background-repeat: repeat;
  }
}
</style>
