<template>
  <v-date-picker
    class="booking-date-picker"
    v-model="date"
    first-day-of-week="1"
    no-title
    :header-date-format="
      (v) => {
        return moment(v, `YYYY-MM`).format(`MMMM, YYYY`);
      }
    "
    color="primary"
  />
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  name: "BookingDatePicker",
  data() {
    return {
      moment,
    };
  },
  computed: {
    ...mapState("TableBookingStore", ["selected_booking_date"]),
    date: {
      get() {
        return this.selected_booking_date;
      },
      set(value) {
        this.set_selected_date(value);
      },
    },
  },
  methods: {
    ...mapActions("TableBookingStore", ["set_selected_date"]),
  },
  mounted() {
    this.set_selected_date(moment().format("YYYY-MM-DD"));
  },
};
</script>
