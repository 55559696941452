<template>
  <div class="timeline-section noselect">
    <div class="table-names">
      <span class="font-weight-bold"> {{ zoneName }} </span>
      <span
        v-for="table in tables"
        :key="`table_name_${table.id}`"
        :class="{ offline: table.priority == 1 }"
      >
        {{
          `${table.name} (${table.capacity})` +
          (table.priority == 1 ? " OFF" : "")
        }}
      </span>
    </div>
    <div class="navigation-wrapper">
      <v-icon class="axis-navigation left" @click="scroll_timeline(-1)">
        mdi-chevron-left
      </v-icon>
      <v-icon
        class="axis-navigation right"
        :style="right_chevron_position"
        @click="scroll_timeline(1)"
      >
        mdi-chevron-right
      </v-icon>
    </div>
    <div
      class="timeline-wrapper"
      :id="wrapperId"
      :style="timeline_wrapper_width"
    >
      <div
        class="timeline-axis"
        :style="`grid-template-columns: repeat(${24 * 4 - 1}, ${
          (pixels_per_hour * scale) / 16
        }px); padding-left: ${((timeline_node_width / 2) * scale) / 4}px;`"
      >
        <span
          v-for="(value, i) in axis_values"
          class="text-center"
          :key="`axis_v_${i}`"
        >
          {{ value }}
        </span>
      </div>
      <TableTimeline
        v-for="(table, i) in tables"
        :key="`timeline_${table.id}`"
        :table="table"
        :scale="scale"
        :business-hour-positions="
          business_hours_indicator_data
            ? [0, ...business_hours_indicator_data.map((i) => i.position)]
            : []
        "
        :bg-offset="(tables.length - i - 1) * 12"
      />
      <div
        class="indicator time text-center"
        :style="`left: ${time_indicator_position}px;`"
      >
        <span>Time</span>
      </div>
      <div
        class="indicator text-center"
        v-for="(indicator, i) in business_hours_indicator_data"
        :key="`hour_indicator_${i}`"
        :style="`left: ${indicator.position}px;`"
      >
        <span>{{ indicator.text }}</span>
      </div>
    </div>
    <span class="summary-text">{{ timeline_summary_text }}</span>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapGetters } from "vuex";

import TableTimeline from "./TableTimeline.vue";

export default {
  name: "BookingTimeline",
  data() {
    return {
      time_indicator_position: 0,
      update_interval: null,
    };
  },
  props: {
    businessHours: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tables: {
      type: Array,
      default: () => {
        return [];
      },
    },
    zoneName: {
      type: String,
      default: "",
    },
    wrapperId: {
      type: String,
      default: "timeline-wrapper",
    },
    scrollPosition: {
      type: Number,
      default: 0,
    },
    scale: {
      type: Number,
      default: 4,
    },
  },
  components: {
    TableTimeline,
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("TableBookingStore", ["settings"]),
    ...mapGetters("TableBookingStore", [
      "bookings_chronologically",
      "intervals_per_hour",
      "timeline_node_width",
      "pixels_per_minute",
      "pixels_per_hour",
    ]),
    axis_values() {
      let am_values = Array(3).fill("");
      let pm_values = [];
      for (let i = 1; i < 13; i++) {
        am_values =
          i == 12
            ? [...am_values, `${i}pm`, ...Array(3).fill("")]
            : [...am_values, `${i}am`, ...Array(3).fill("")];
        pm_values =
          i == 12 ? pm_values : [...pm_values, `${i}pm`, ...Array(3).fill("")];
      }
      return [...am_values, ...pm_values];
    },
    timeline_wrapper_width() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.drawer
          ? "max-width: calc(100vw - 260px - 201px);"
          : "max-width: calc(100vw - 67px - 201px);";
      }
      return "max-width: calc(100vw - 201px);";
    },
    right_chevron_position() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.drawer
          ? "left: calc(100vw - 260px - 218px);"
          : "left: calc(100vw - 67px - 218px);";
      }
      return "left: calc(100vw - 218px);";
    },
    business_hours_indicator_data() {
      let indicator_data = [];
      for (const [i, hour] of this.businessHours.entries()) {
        const [from_hour, from_minute] = hour.from_time.split(":");
        indicator_data.push({
          position:
            from_hour *
              this.timeline_node_width *
              this.intervals_per_hour *
              (this.scale / 4) +
            from_minute * this.pixels_per_minute * (this.scale / 4),
          text: i == 0 ? "Day Start" : "Break End",
        });
        const [to_hour, to_minute] = hour.to_time.split(":");
        indicator_data.push({
          position:
            to_hour *
              this.timeline_node_width *
              this.intervals_per_hour *
              (this.scale / 4) +
            to_minute * this.pixels_per_minute * (this.scale / 4),
          text: i == this.businessHours.length - 1 ? "Day End" : "Break Start",
        });
      }
      const all_positions = indicator_data.map((i) => i.position);
      const duplicated_positions = all_positions.filter(
        (position, index) => all_positions.indexOf(position) !== index
      );
      return indicator_data.filter(
        (i) => !duplicated_positions.includes(i.position)
      );
    },
    timeline_summary_text() {
      const total_bookings = this.bookings_chronologically.filter((booking) =>
        this.tables.map((table) => table.id).includes(booking.table_id)
      );
      const total_guests = total_bookings
        .map((b) => {
          return b.guest_count;
        })
        .reduce((a, b) => a + b, 0);
      const total_extra_guests = total_bookings
        .map((b) => b.extra_guest_count || 0)
        .reduce((a, b) => a + b, 0);

      const guests = total_extra_guests
        ? `${total_guests} + ${total_extra_guests}`
        : total_guests;
      return `Total: ${total_bookings.length} bookings, ${guests} guests`;
    },
  },
  methods: {
    ...mapActions("TableBookingStore", ["get_tables", "get_settings"]),
    scroll_timeline(value) {
      const el = document.getElementById(this.wrapperId);
      const distance = this.timeline_node_width * this.scale * 3 * value;
      this.$emit("scroll-timeline", el.scrollLeft + distance);
    },
    update_time_indicator_position() {
      const now = moment();
      const hours_offset =
        (now.format("HH") *
          this.scale *
          this.timeline_node_width *
          this.intervals_per_hour) /
        4;
      const minutes_offset =
        now.format("mm") * this.pixels_per_minute * (this.scale / 4);
      this.time_indicator_position = hours_offset + minutes_offset;
    },
    scroll_to_current_time() {
      this.$emit(
        "scroll-timeline",
        this.time_indicator_position - this.timeline_node_width * this.scale
      );
    },
  },
  watch: {
    scrollPosition(position) {
      const el = document.getElementById(this.wrapperId);
      el.scroll(position, 0);
    },
    scale() {
      this.update_time_indicator_position();
      setTimeout(this.scroll_to_current_time, 10);
    },
  },
  async mounted() {
    await this.get_settings(this.shop_id);
    this.update_time_indicator_position();
    const seconds_to_full_minute = 60 - moment().format("ss");
    setTimeout(() => {
      this.update_time_indicator_position();
      this.update_interval = setInterval(
        () => this.update_time_indicator_position(),
        60000
      );
    }, seconds_to_full_minute * 1000);
    this.scroll_to_current_time();
  },
  beforeDestroy() {
    if (this.update_interval) window.clearInterval(this.update_interval);
  },
};
</script>

<style lang="scss" scoped>
.timeline-section {
  grid-column: 1 / span 2;
  display: flex;
  position: relative;
  margin-bottom: 52px;
  .navigation-wrapper {
    height: 0;
    position: relative;
    .axis-navigation {
      position: absolute;
      top: 6px;
      background-color: #f7f7f7;
      z-index: 1;
      &.left {
        left: -1px;
      }
      &.minus {
        left: -55px;
      }
      &.plus {
        left: -35px;
      }
    }
  }
  .table-names {
    width: 150px;
    span {
      margin-top: 4px;
      height: 28px;
      display: block;
      color: #666666;
      font-size: 15px;
      &.offline {
        color: #a6a6a6;
      }
    }
  }
  .timeline-axis {
    display: grid;
    position: relative;
    grid-template-rows: 28px;

    span {
      display: block;
      font-size: 15px;
      color: #666666;
      margin-top: 5px;
    }
  }
  .timeline-wrapper {
    border-radius: 8px;
    display: grid;
    position: relative;
    grid-gap: 3px;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 0;
    }
    scroll-behavior: smooth;
    .indicator {
      width: 1px;
      height: calc(100% - 33px);
      position: absolute;
      display: relative;
      top: 33px;
      background: transparent;
      border-left: 1px dashed #666666;
      span {
        width: 100px;
        top: calc(50% - 8px);
        right: -40px;
        position: absolute;
        font-size: 10px;
        color: #666666;
        transform: rotate(-90deg);
      }
      &.time {
        border-color: #ff8080;
        span {
          color: #ff8080;
        }
      }
    }
  }
  .summary-text {
    display: block;
    height: 20px;
    position: absolute;
    bottom: -30px;
    right: 10px;
    font-size: 15px;
    color: #666666;
    font-weight: bold;
  }
}
</style>
