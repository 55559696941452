var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline",class:{
    '--drop-target': _vm.is_drop_target,
  },style:(("grid-template-columns: repeat(" + (6 * _vm.intervals_per_hour * _vm.scale) + ", " + _vm.timeline_node_width + "px);")),on:{"drop":function($event){return _vm.on_drop($event)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();return _vm.on_drag_enter.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.on_drag_leave.apply(null, arguments)}}},[_vm._l((_vm.bookings),function(booking){return _c('div',{key:("booking_node_" + (booking.id)),staticClass:"booking-node",class:{ disabled: booking.status === _vm.BOOKING_STATUS.NO_SHOW },style:(("\n      grid-column: " + (_vm.get_booking_node_columns(booking)) + ";\n      background-color: " + (_vm.status_map[booking.status].color) + "\n    ")),attrs:{"draggable":""},on:{"click":function($event){return _vm.select_booking(booking)},"dragstart":function($event){return _vm.on_start_drag($event, booking)}}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"name text-truncate flex-shrink-1"},[_vm._v(_vm._s(_vm.get_booking_node_text(booking)))]),(!booking.parent_booking_id)?[_c('span',{staticClass:"display-inline-block mx-1"},[_vm._v(" ("+_vm._s(booking.extra_guest_count ? ((booking.guest_count) + " + " + (booking.extra_guest_count)) : booking.guest_count)+") ")])]:_vm._e(),_c('v-spacer'),_c('div',{staticClass:"d-flex fill-height mr-2"},[(!booking.parent_booking_id)?[(booking.notes !== null)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"white"},domProps:{"textContent":_vm._s("$note-circle")}}):_vm._e()]:_vm._e(),(
            ![
              _vm.BOOKING_STATUS.NEW,
              _vm.BOOKING_STATUS.CANCELLED,
              _vm.BOOKING_STATUS.CONFIRMED ].includes(booking.status)
          )?_c('v-icon',{attrs:{"small":"","color":_vm.status_map[booking.status].icon_color},domProps:{"textContent":_vm._s(_vm.status_map[booking.status].icon)}}):_vm._e(),(
            ![
              _vm.BOOKING_STATUS.NO_SHOW,
              _vm.BOOKING_STATUS.NEW,
              _vm.BOOKING_STATUS.CANCELLED ].includes(booking.status)
          )?_c('v-icon',{attrs:{"small":"","color":"primary"},domProps:{"textContent":_vm._s(
            ("mdi-check" + (booking.status === _vm.BOOKING_STATUS.COMPLETED ? '-all' : ''))
          )}}):_vm._e()],2)],2)])}),_vm._l((_vm.break_section_data),function(section,i){return _c('div',{key:("break_" + i),staticClass:"break-section",style:(("left: " + (section.start) + "px; width: " + (section.end - section.start) + "px; background-image: url(" + (require('@/assets/stripes.png')) + "); background-position: left -" + _vm.bgOffset + "px"))})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }